@charset "UTF-8";

:root {
  --main_primary: #008cd1;
  --main_secondary: #16d7c0;
  --main_secondary_dark: #16d7c0;
  --main_text: #3c3c3c;
  --main_light_gray: #989898;
  --main_body_text_color: #4b4b4b;
  --color_bs_pink: #d63384;
  --color_bs_red: #dc3545;
  --color_bs_orange: #fd7e14;
  --color_bs_yellow: #ffc107;
  --color_bs_green: #198754;
  --color_bs_teal: #20c997;
  --color_bs_dark_teal: #1aac80;
  --color_bs_cyan: #16d7c0;
  --color_bs_white: #fff;
  --color_bs_gray: #6c757d;
  --color_bs_gray_dark: #343a40;
  --text_hindi: #e1aa03;
  --main_op_back: #c1d0e1;
  --main_black: #1a1a1a;
  --main_green_color: #5BC2AB;
  --main_claim_back: #e4eef9;
  --ckyc_color: #ffc10730;
  --main_light_green: #47D990;
  --color_bs_purpal: #fcefef;
  --main_text_black: #111;
  --color_border_purpal: #fcefef;
  --main_secondary-bg: #16d7c0;
  --main_light_sky_bg: #2cb2b31a;
  --color_bs_red_light: #dc354639
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-ExtraBold.eot);
  src: url(../fonts/Poppins-ExtraBold.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-ExtraBold.woff2) format('woff2'), url(../fonts/Poppins-ExtraBold.woff) format('woff'), url(../fonts/Poppins-ExtraBold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-Black.eot);
  src: url(../fonts/Poppins-Black.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Black.woff2) format('woff2'), url(../fonts/Poppins-Black.woff) format('woff'), url(../fonts/Poppins-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-Bold.eot);
  src: url(../fonts/Poppins-Bold.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Bold.woff2) format('woff2'), url(../fonts/Poppins-Bold.woff) format('woff'), url(../fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-ExtraLight.eot);
  src: url(../fonts/Poppins-ExtraLight.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-ExtraLight.woff2) format('woff2'), url(../fonts/Poppins-ExtraLight.woff) format('woff'), url(../fonts/Poppins-ExtraLight.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-Medium.eot);
  src: url(../fonts/Poppins-Medium.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Medium.woff2) format('woff2'), url(../fonts/Poppins-Medium.woff) format('woff'), url(../fonts/Poppins-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-Regular.eot);
  src: url(../fonts/Poppins-Regular.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Regular.woff2) format('woff2'), url(../fonts/Poppins-Regular.woff) format('woff'), url(../fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-Light.eot);
  src: url(../fonts/Poppins-Light.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Light.woff2) format('woff2'), url(../fonts/Poppins-Light.woff) format('woff'), url(../fonts/Poppins-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-Thin.eot);
  src: url(../fonts/Poppins-Thin.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Thin.woff2) format('woff2'), url(../fonts/Poppins-Thin.woff) format('woff'), url(../fonts/Poppins-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-SemiBold.eot);
  src: url(../fonts/Poppins-SemiBold.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-SemiBold.woff2) format('woff2'), url(../fonts/Poppins-SemiBold.woff) format('woff'), url(../fonts/Poppins-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap
}


html {
  font-family: poppins, sans-serif !important
}

body {
  font-family: poppins !important;
  line-height: 24px;
  letter-spacing: .4px;
  color: #008cd1;
  background-color: #fff;
  font-size: 15px;
  color: #4b4b4b;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  padding: 0 !important
}

a:focus,
a:focus-visible,
button:focus,
button:focus-visible,
input:focus,
input:focus-visible {
  outline: 0 !important;
  box-shadow: none !important;
  text-decoration: none !important;
}

.form-select:focus {
  border-color: #ced4da;
  outline: 0;
  box-shadow: none
}

li,
ul {
  margin: 0;
  padding: 0;
  list-style: none
}

a,
a:hover {
  text-decoration: none !important;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -ms-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out
}

img {
  width: auto;
  max-width: 100%
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 5px
}

::-webkit-scrollbar-track {
  background: #f1f1f1
}

::-webkit-scrollbar-thumb {
  background: #008cd1
}

::-webkit-scrollbar-thumb:hover {
  background: #008cd1
}

.overflow_hiddenn {
  overflow: hidden;
}


.error_section {
  padding: 100px 15px;
  text-align: center;
}

.error_section .error {
  font-size: 150px;
  color: #d72329;
  text-shadow:
    1px 1px 1px #b50107,
    2px 2px 1px #b50107,
    3px 3px 1px #b50107,
    4px 4px 1px #b50107,
    5px 5px 1px #b50107,
    6px 6px 1px #b50107,
    7px 7px 1px #b50107,
    8px 8px 1px #b50107,
    25px 25px 8px rgba(0, 0, 0, 0.2);
}

.error_section .page {
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.error_section .back-home {
  display: inline-block;
  border: 2px solid #222;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 15px 15px -11px rgba(0, 0, 0, 0.4);
  background: #222;
  border-radius: 6px;
}

.error_section .back-home:hover {
  background: #222;
  color: #ddd;
}


/* ********************************************Custom_loder_css_end***************************************** */

.table_csutome_content {
  padding: 15px 5px;
  box-shadow: inherit !important;
  margin-top: 0px !important;
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
}

.table_csutome_content p,
.table_csutome_content span {
  margin: 0;
  font-size: 12px !important;
}

.timeline-item {
  background: #fff;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: 3px;
  padding: 12px;
  margin: 0 auto;
  width: auto;
  min-height: 98px;
  margin-bottom: 25px;
}

.animated-background {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eeeeee;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
  background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  -webkit-background-size: 800px 104px;
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.background-masker {
  background: #fff;
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.outlined .background-masker {
  border: 1px solid #ddd;
}

.outlined:hover .background-masker {
  border: none;
}

.outlined:hover .background-masker:hover {
  border: 1px solid #ccc;
  z-index: 1;
}

.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  left: 40px;
  right: 0;
  height: 10px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: 10px;
  left: 40px;
  height: 8px;
  width: 10px;
}

.background-masker.header-bottom {
  top: 18px;
  height: 6px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
  top: 24px;
  height: 6px;
}

.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: 590px;
  right: 0;
}

.background-masker.subheader-right {
  left: 490px;
}

.background-masker.subheader-bottom {
  top: 30px;
  height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
  top: 40px;
  left: 0;
  right: 0;
  height: 6px;
}

.background-masker.content-top {
  height: 20px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
  width: auto;
  left: 380px;
  right: 0;
  top: 60px;
  height: 8px;
}

.background-masker.content-second-line {
  top: 68px;
}

.background-masker.content-second-end {
  left: 550px;
  top: 74px;
}

.background-masker.content-third-line {
  top: 82px;
}

.background-masker.content-third-end {
  left: 300px;
  top: 88px;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }

  100% {
    background-position: 468px 0
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }

  100% {
    background-position: 468px 0
  }
}


@media (max-width: 767px) {

  .table_csutome_content p,
  .table_csutome_content span {
    margin: 0;
    font-size: 10px !important;
  }
}

/* ********************************************Custom_loder_css_end***************************************** */


.main_custom_popup {
  position: fixed;
  z-index: 9999;
  height: 100vh;
  width: 100%;
  background: #28354f5c;
  left: 0;
  top: 0;
  padding: 20px;
  margin: auto;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
}

.main_custom_popup .main_custom_popup_inner {
  border: 1px solid #ddd;
  border-radius: 10px;
  height: auto;
  position: relative;
  background: #fff;
  width: 350px;
  margin: auto;
  margin-top: 0;
  box-shadow: 0px 0px 10px 0px var(--main_primary)6b;
  overflow: hidden;
}

#login_popup_main.main_custom_popup .main_custom_popup_inner {
  width: 100%;
  max-width: 450px;
 background-color: #fff;
  margin: auto;
}

.main_custom_popup .main_custom_popup_inner .close_btn_pop {
  position: absolute;
  right: 21px;
  top: 10px;
  cursor: pointer;
}

.main_custom_popup .main_custom_popup_inner .close_btn_pop img {
  width: 15px !important;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_header {
  font-size: 20px;
  font-weight: 600;
  color: #008cd1;
  padding: 11px 20px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body {
  padding: 5px 20px 15px 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 320px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body::-webkit-scrollbar {
  width: 0px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data {
  padding-bottom: 30px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .image_text_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .image_text_box .img_box_login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .image_text_box .img_box_login img {
  width: 180px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .image_text_box .login_text_title {
  font-size: 22px;
  font-weight: 600;
  color: #008cd1;
  line-height: 31px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .login_details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .login_details a {
  background: #008cd1;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  font-weight: 600;
  padding: 10px 15px;
  line-height: 18px;
  box-shadow: 0px 0px 10px 0px #00bcd48c;
  margin: 0 5px;
  margin-bottom: 15px;
}

.successMessage_card {
  text-align: center;
  border: 1px solid var(--main_claim_back);
  background: #00a0e30d;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.successMessage_card h1 {
  color: #111;
  font-size: 18px;
  letter-spacing: 0;
  font-weight: 600;
  margin: 10px 0px 5px 0px;
}

.success_inner_card .checkmark {
  font-size: 24px;
  color: #fff;
  background: #28a745;
  min-width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  border-radius: 10px;
}

.successMessage_card p {
  margin: 0;
  font-size: 12px;
  line-height: 20px;
}



/* ******************************************main_header_css_start**************************************** */

.main_header {
  position: relative;
  z-index: 9;
  background-color: #fff;
}

.main_header .header_static {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.main_header .header_static .logo_section {
  width: 140px;
}

.main_header .header_static .logo_section img {
  width: 50px;
}

.main_header .header_static .main_menu_inner {
  width: calc(100% - 140px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
}

.main_header .header_static .main_menu_inner .main_menu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.main_header .header_static .main_menu_inner .main_menu nav ul {
  display: flex;
  margin-bottom: 0px;
  line-height: 29px;
}

.main_header .header_static .main_menu_inner .main_menu nav ul li.login_dropdown {
  position: relative;
}

.main_header .header_static .main_menu_inner .main_menu nav ul li.login_dropdown>a img {
  width: 23px;
  margin-right: 5px;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li>a {
  display: block;
  padding: 7px 15px;
  color: #111;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 0;
  transition: 0.5s;
  position: relative;
  cursor: pointer;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li>a.highlight {
  border: 1px solid #008cd1;
  border-radius: 5px;
  height: 35px;
  line-height: 30px;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li>a.highlight:hover {
  background-color: #008cd1;
  color: #fff;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li>a small.down_icon {
  border-bottom: 2px solid #111;
  border-right: 2px solid #111;
  width: 8px;
  height: 8px;
  display: inline-block;
  transform: rotate(45deg);
  position: relative;
  top: -3px;
  margin-left: 4px;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li:hover>a small.down_icon {
  border-color: #008cd1;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li:hover>a {
  color: #008cd1;
}

.main_header .header_static .main_menu_inner .main_menu .login_box {
  margin-left: 10px;
}

.main_header .header_static .main_menu_inner .main_menu .login_box button {
  width: 70px;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  background-image: linear-gradient(110deg, #008cd1 0%, #008cd1 100%);
  border: none;
  height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  z-index: 1;
}

.main_header .header_static .main_menu_inner .main_menu .login_box button:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #008cd1;
  border-radius: 5px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5), 7px 7px 20px 0px rgba(0, 0, 0, .1), 4px 4px 5px 0px rgba(0, 0, 0, .1);
  transition: all 0.3s ease;
}

.main_header .header_static .main_menu_inner .main_menu .login_box button:hover:after {
  left: 0;
  width: 100%;
  border-radius: 5px;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu {
  position: absolute;
  min-width: 170px;
  padding: 10px 0px;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  left: inherit;
  right: 0;
  z-index: 9;
  top: 45px;
  height: 0px;
  display: none;
  visibility: hidden;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li:hover .sub_menu {
  display: block;
  height: auto;
  visibility: visible;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu li button,
.main_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu li a {
  color: #111;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 0;
  display: block;
  text-transform: capitalize;
  font-weight: 400;
  padding: 7px 10px;
  border: none;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu li button:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu li button:hover .main_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu li a:hover {
  color: #009687 !important;
}

.main_header .header_static .main_menu_inner #menu_open_btn {
  display: none;
}

.main_header .menubar_row .mobile_menu_open {
  padding: 0;
  width: 20px;
  border: none;
  background-color: transparent;
  display: none;
}

@media (max-width:991px) {
  .main_header .menubar_row .logo {
    width: 140px;
  }
  .main_header .header_static .main_menu_inner .main_menu {
    width: auto;
  }
  .main_header .header_static .main_menu_inner {
    width: auto;
  }
  .main_header .header_static .logo_section {
    text-align: center;
    margin: 0 auto;
  }
  .main_header .header_static {
    display: flex;
    position: relative;
    width: 100%;
    background: #fff;
    top: 0;
    padding: 7px 0px;
    left: 0px;
  }
  .main_header .header_static .main_menu_inner .main_menu nav ul {
    margin: 0px;
    padding: 0px 10px;
  }
  .main_header .header_static .main_menu_inner .main_menu #man_menu_show_hide {
    width: 100%;
    position: fixed;
    height: calc(100vh - 51px);
    background: #fff;
    left: 0px;
    max-width: 100%;
    box-shadow: none;
    border-right: none;
    top: 50px;
    z-index: 9999;
    overflow-y: auto;
  }
  .main_header .header_static .main_menu_inner .main_menu nav ul {
    display: block;
    overflow-y: auto;
  }
  .main_header .header_static .main_menu_inner .main_menu nav ul li>a {
    padding: 10px 10px 10px 0px;
    font-size: 16px;
  }
  .main_header .header_static .main_menu_inner #menu_open_btn {
    display: block;
    position: absolute;
    left: 5px;
    background: #fff;
    -webkit-box-shadow: 0 0 6px 1px rgba(52, 105, 203, .16);
    -moz-box-shadow: 0 0 6px 1px rgba(52, 105, 203, .16);
    box-shadow: 0 0 6px 1px rgba(52, 105, 203, .16);
    text-align: center;
    margin: 0px auto;
    border-radius: 8px;
    height: 35px;
    width: 35px;
    line-height: 35px;
    top: 7px;
    z-index: 9999;
  }
  .main_header .header_static .main_menu_inner #menu_open_btn img {
    width: 25px;
  }
  .main_header .header_static .main_menu_inner #menu_open_btn.close .main-menu-btn-icon {
    height: 0;
    background: transparent;
  }
  .main_header .header_static .main_menu_inner #menu_open_btn.close .main-menu-btn-icon:before {
    top: 0 !important;
    -webkit-transform: rotate(-45deg) !important;
    transform: rotate(-45deg) !important;
  }
  .main_header .header_static .main_menu_inner #menu_open_btn.close .main-menu-btn-icon:after {
    top: 0 !important;
    -webkit-transform: rotate(45deg) !important;
    transform: rotate(45deg) !important;
  }
  .main_header .header_static .main_menu_inner #menu_open_btn:hover #man_menu_show_hide {
    height: 0;
    background: transparent;
  }
  .main_header {
    background-color: #fff;
    width: 100%;
  }
  .main_header .header_static .main_menu_inner .main_menu .login_box {
    margin-left: 0;
    margin-right: 15px;
  }
  .main_header .header_static .main_menu_inner .main_menu .login_box button {
    width: auto;
    background-image: none;
    height: auto;
    color: #fff;
    border-radius: 0;
    background: #00a0e300;
    color: #000;
    margin-right: 0px;
    border: 1px solid #00a0e3;
    font-size: 14px;
    border-radius: 5px;
    padding: 1px 12px;
  }
  .main_header .header_static .main_menu_inner .main_menu nav ul li {
    display: block;
  }
}

@media (max-width:768px) {
  .main_header .menubar_row .logo,
  .main_header .menubar_row .logo img {
    width: 100px;
  }
  .mobile_menu_bottom.fixed_bottom_menu {
    display: flex;
  }
  .mobile_menu_bottom {
    padding: 6px 15px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 350px) {
  .mobile_menu_bottom .menu__item span {
    font-size: 11px;
  }
}
.main-menu-btn {
  float: right;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 25px;
  text-indent: 25px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.main-menu-btn-icon,
.main-menu-btn-icon:before,
.main-menu-btn-icon:after {
  position: absolute;
  top: 50%;
  left: 8px;
  height: 1px;
  width: 20px;
  background: #262525;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  z-index: 9999;
}
.main-menu-btn-icon {
  width: 14px;
}
.main-menu-btn-icon:before {
  content: '';
  top: -8px;
  left: 0;
}
.main-menu-btn-icon:after {
  content: '';
  top: 8px;
  left: 0;
}
@media (min-width: 768px) {
  .main-menu-btn {
    position: absolute;
    top: -99999px;
  }
}
@media (max-width: 767px) {
  .main-menu-btn {
    float: left;
  }
}
.d_flex {
  display: flex;
}

.flex_Equal {
  justify-content: space-between;
}

.flex_Center {
  justify-content: center;
}

.flex_Start {
  justify-content: flex-start;
}

.flex_End {
  justify-content: flex-end;
}

.flex_Middel {
  align-items: center;
}

.flex_Top {
  align-items: flex-start;
}

.flex_Bottom {
  align-items: flex-end;
}

.flex_column {
  flex-direction: column;
}

.flex_wrap {
  flex-wrap: wrap;
}
.flex::after,
.flex::before {
  display: none !important;
}
.fw_500 {
  font-weight: 500;
}
.fw_600 {
  font-weight: 600;
}
.fw_700 {
  font-weight: 700;
}
.d_none {
  display: none;
}
.error {
  color: #ec1c24;
}
.text_center {
  text-align: center;
}
.text_left {
  text-align: left;
}
.text_right {
  text-align: right;
}
.text_uppercase {
  text-transform: uppercase;
}
.text_capitalize {
  text-transform: capitalize;
}
.text_justfy {
  text-align: justify;
}
.ml_10 {
  margin-left: 10px;
}

.m_15 {
  margin: 15px;
}

.mr_15 {
  margin-right: 15px;
}

.mr_10 {
  margin-right: 10px;
}

.ml_30 {
  margin-left: 50px;
}

.mt_7 {
  margin-top: 7px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_20 {
  margin-top: 20px;
}

.mb_5 {
  margin-bottom: 5px !important;
}

.mx_0 {
  margin-left: 0;
  margin-right: 0;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mt_40 {
  margin-top: 40px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mb_25 {
  margin-bottom: 25px;
}

.mb_35 {
  margin-bottom: 35px;
}

.mb_0 {
  margin-bottom: 0 !important;
}

.mt_35 {
  margin-top: 35px;
}

.border_none {
  border: none !important;
}

.border_bottom {
  border-bottom: 1px solid #ddd;
}

.border_bottom1 {
  border-bottom: 1px solid #ddd;
}

.border_top1 {
  border-top: 1px solid #fff;
}

.border_top_gray1 {
  border-top: 1px solid #ddd;
}

.border_bottom_dashed {
  border-bottom: 2px dashed #fff;
}

.background_none {
  background-color: transparent !important;
}

.sliver_background {
  background: #f4feff;
}

.top_shodw {
  box-shadow: 0 -15px 20px 0 #cccccc73;
}

.clip_path_none {
  clip-path: none !important;
}

.P_15 {
  padding: 15px;
}

.p_0 {
  padding: 0;
}

.pb_15 {
  padding-bottom: 15px;
}

.pb_10 {
  padding-bottom: 10px;
}

.pb_60 {
  padding-bottom: 60px;
}

.pb_80 {
  padding-bottom: 80px;
}

.pb_200 {
  padding-bottom: 200px;
}

.pt_7 {
  padding-top: 7px;
}

.pt_60 {
  padding-top: 60px;
}

.pt_80 {
  padding-top: 80px;
}

.pt_100 {
  padding-top: 100px;
}

.pt_150 {
  padding-top: 150px;
}

.pt_30 {
  padding-top: 30px;
}

.pl_25 {
  padding-left: 25px;
}

.pl_15 {
  padding-left: 15px;
}

.p_070 {
  padding: 0 60px;
}

.py_5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.px_10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pr_15 {
  padding-right: 15px;
}

.mt_min_110 {
  margin-top: -110px;
}

.z_index999 {
  z-index: 999;
}

.li_width li {
  width: 25%;
  font-size: 12px;
  font-weight: 700;
}

.list_css li {
  font-size: 16px;
  font-weight: 600;
  line-height: 34px;
  position: relative;
  padding-left: 20px;
}

.list_css li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 12px;
  width: 12px;
  height: 12px;
  background-color: #008cd1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.ul_style li {
  margin: 3px;
}

.ul_style li>div {
  padding: 5px;
  border: 1px solid #363636;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.ul_style li>div:hover {
  border: 1px solid #008cd1;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.ul_style li>div img {
  max-width: 130px;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.ul_style li>div:hover img {
  filter: grayscale(10);
  -webkit-filter: grayscale(10);
}

.edit_btn {
  border: none;
  background: #16d7c0;
  color: #fff;
  font-size: 15px;
  height: 35px;
  width: 35px;
  margin-left: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.edit_btn:hover {
  background: #363636;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.buy_now_btn {
  border: none;
  background: #16d7c0;
  color: #fff;
  font-size: 13px;
  margin-bottom: 5px;
  height: 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.buy_now_btn:hover {
  background: #363636;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.fin_logo img {
  width: 150px;
}

.w_100 {
  width: 100%;
}

.w_50 {
  width: 50%;
}

.h_100 {
  height: 100%;
}

.h_50 {
  height: 50%;
}

.cursor_pointer {
  cursor: pointer;
}

.panel_active_class.click_btn small {
  color: #008cd1;
}

.change_icon.rotate_icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.text_bottom_border {
  position: relative;
}

.text_bottom_border::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  width: 150px;
  text-align: center;
  margin: 0 auto;
  height: 3px;
  background: #16d7c0;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.text_bottom_border_white {
  position: relative;
}

.text_bottom_border_white::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 70px;
  text-align: center;
  height: 3px;
  background-color: #fff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.text_green {
  color: #008cd1;
}

.text_dark {
  color: #363636;
}

.text_white {
  color: #fff !important;
}

.border_red50 {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.fs_8 {
  font-size: 8px;
}

.fs_9 {
  font-size: 9px;
}

.fs_10 {
  font-size: 10px;
}

.fs_11 {
  font-size: 11px;
}

.fs_12 {
  font-size: 12px;
}

.fs_13 {
  font-size: 13px;
}

.fs_14 {
  font-size: 14px;
}

.fs_15 {
  font-size: 15px;
}

.fs_16 {
  font-size: 16px;
}

.fs_17 {
  font-size: 17px;
}

.fs_18 {
  font-size: 18px;
}

.fs_19 {
  font-size: 19px;
}

.fs_20 {
  font-size: 20px;
}

.fs_21 {
  font-size: 21px;
}

.fs_22 {
  font-size: 22px;
}

.fs_23 {
  font-size: 23px;
}

.fs_24 {
  font-size: 24px;
}

.fs_25 {
  font-size: 25px;
}

.fs_26 {
  font-size: 26px;
}

.fs_27 {
  font-size: 27px;
}

.fs_28 {
  font-size: 28px;
}

.fs_29 {
  font-size: 29px;
}

.fs_30 {
  font-size: 30px;
}

.fs_31 {
  font-size: 31px;
}

.fs_32 {
  font-size: 32px;
}

.fs_33 {
  font-size: 33px;
}

.fs_34 {
  font-size: 34px;
}

.fs_35 {
  font-size: 35px;
}

.fs_36 {
  font-size: 36px;
}

.fs_37 {
  font-size: 37px;
}

.fs_38 {
  font-size: 38px;
}

.fs_39 {
  font-size: 39px;
}

.fs_40 {
  font-size: 40px;
}

.fs_41 {
  font-size: 41px;
}

.fs_42 {
  font-size: 42px;
}

.fs_43 {
  font-size: 43px;
}

.fs_44 {
  font-size: 44px;
}

.fs_45 {
  font-size: 45px;
}

.fs_46 {
  font-size: 46px;
}

.line_height_24 {
  line-height: 40px;
}

.line_height_34 {
  line-height: 34px;
}

.form_group {
  width: 100%;
  margin-bottom: 15px;
}

.form_group label {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.form_group .form_control {
  height: 35px;
  width: 100%;
  padding: 6px 10px;
  font-size: 14px;
  color: #333;
  border: 1px solid #999;
  background-color: #fff;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.form_group .form_control_span {
  height: 35px;
  width: 100%;
  padding: 5px 10px;
  font-size: 14px;
  color: #747e8c;
  border: 1px solid #ddd !important;
  background-color: #ddd;
  display: block;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.form_group .form_control:focus {
  box-shadow: none !important;
  outline-color: #008cd1;
  outline-width: 1px !important;
}

.form_group .form_control[type="file"] {
  border: none !important;
  height: auto;
  padding: 0px;
  border-bottom: 1px solid #ddd !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}
/* ********************************************************************************* */


input:focus,
select:focus {
  box-shadow: none !important;
  border: 1px solid #008cd1 !important;
  outline-width: 0 !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #008cd1;
}

.otp_btn,
.next-button {
  width: 100px;
  height: 36px;
  background-color: #16d7c0;
  color: #fff;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.next-button svg {
  margin-left: 5px;
}

.otp_btn:hover,
.next-button:hover {
  background-color: #008cd1;
  color: #fff;
}

/* ********************************************payment_page_css_start***************************************** */

.payment_page_css .payment_block {
  display: block;
  overflow: inherit;
  padding: 40px 10px 10px 10px;
  position: relative;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #0d398f54;
  border-radius: 5px;
}

.payment_page_css h1 {
  color: #008cd1;
}

.payment_page_css .payment_block li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  padding: 8px 5px;
  min-width: 100%;
  box-shadow: none;
  border-bottom: 1px dashed #d6d6d6;
  font-size: 12px;
  font-weight: 500;
}

.payment_page_css .payment_block li.last_child {
  border: none;
  padding: 0;
}

.payment_page_css .payment_block li label {
  font-weight: 700;
  color: #008cd1;
}

.payment_page_css .payment_block .payment_note p span {
  font-weight: 700;
}

.payment_page_css .payment_block .payment_note p {
  padding: 5px;
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 0;
  background: #ffd3d3;
  color: black;
  border-radius: 5px;
}

.payment_page_css .payment_block .download_policy {
  position: absolute;
  float: inherit !important;
  padding: 5px 24px;
  background: #04cb64;
  margin: 0;
  color: white;
  border: 0;
  border-radius: 100px 0px 0px 100px;
  right: -1px;
  top: 0;
  cursor: pointer;
}

.payment_page_css .payment_img_box {
  text-align: center;
}

.payment_page_css .payment_img_box img {
  max-width: 450px;
  width: 100%;
}

@media (max-width: 767px) {
  .payment_page_css .py_order_2 {
    order: 2;
  }

  .payment_page_css .py_order_1 {
    order: 1;
  }
}

.starcolor::after {
  content: " *";
  color: red;
}

/* ********************************************payment_page_css_end***************************************** */

/* *********************************************************************************************** */


@media (max-width: 1156px) {
  .home_banner_part #home_slider .slide_img_box img {
    text-align: center;
    margin: auto;
    width: 100%;
    object-fit: cover;
    height: calc(100vh - 150px);
  }
}

@media (max-width: 992px) {
  .home_banner.d_flex {
    display: block !important;
  }

  .home_banner_part .text_box {
    max-width: 100%;
    min-height: auto;
  }

  .home_banner_part #home_slider {
    max-width: calc(100% - 0px);
  }

  .left_design .col-lg-6.col-md-12.pt_60 {
    padding-top: 0;
  }


  .about_content {
    margin-top: 50px;
  }

  .ul_style li>div img {
    max-width: 90px;
  }
}

@media (max-width: 768px) {
  .mobile_margin_btn {
    margin: 20px 0px;
  }

  .order_1 {
    order: 1;
  }

  .order_2 {
    order: 2;
  }

  .ul_style li>div img {
    max-width: 80px;
  }
}

@media (max-width: 576px) {
  .home_banner_part #home_slider {
    display: none;
  }

  .home_banner {
    width: 100%;
  }

  .fs_24 {
    font-size: 18px;
  }

  .fs_34 {
    font-size: 24px;
  }

  .home_banner_part .text_box {
    padding-left: 0px;
    margin: auto;
  }

  .app_btn {
    margin-bottom: 10px;
  }

  .main_popup .main_popup_wrapper {
    max-width: 280px;
  }

  .ul_style li>div img {
    max-width: 70px;
  }

  .home_banner_part {
    box-shadow: 0px 15px 20px 0px #36363614;
    height: 450px;
  }

  .home_banner {
    top: 20px;
    height: 450px;
  }

  .home_banner .fs_15.mb_10 {
    font-weight: 700;
  }
}


@media (max-width: 1156px) {
  .home_banner_part #home_slider .slide_img_box img {
    text-align: center;
    margin: auto;
    width: 100%;
    object-fit: cover;
    height: calc(100vh - 150px);
  }
}

@media (max-width: 992px) {
  .home_banner.d_flex {
    display: block !important;
  }

  .home_banner_part .text_box {
    max-width: 100%;
    min-height: auto;
  }

  .home_banner_part #home_slider {
    max-width: calc(100% - 0px);
  }

  .left_design .col-lg-6.col-md-12.pt_60 {
    padding-top: 0;
  }

  .about_content {
    margin-top: 50px;
  }

  .ul_style li>div img {
    max-width: 90px;
  }
}

@media (max-width: 768px) {
  .order_1 {
    order: 1;
  }

  .order_2 {
    order: 2;
  }

  .ul_style li>div img {
    max-width: 80px;
  }
}

@media (max-width: 576px) {
  .home_banner_part #home_slider {
    display: none;
  }

  .home_banner {
    width: 100%;
  }

  .fs_24 {
    font-size: 18px;
  }

  .fs_34 {
    font-size: 24px;
  }

  .home_banner_part .text_box {
    padding-left: 0px;
    margin: auto;
  }

  .app_btn {
    margin-bottom: 10px;
  }


  .main_popup .main_popup_wrapper {
    max-width: 280px;
  }

  .ul_style li>div img {
    max-width: 70px;
  }

  .home_banner_part {
    box-shadow: 0px 15px 20px 0px #36363614;
    height: 450px;
  }

  .home_banner {
    top: 20px;
    height: 450px;
  }

  .home_banner .fs_15.mb_10 {
    font-weight: 700;
  }
  .main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .login_details {
    flex-wrap: wrap;
  }
}

.model_headers {
  border-bottom: 0 none;
}

span.error-msg {
  color: red;
  font-size: 12px;
}

.errorBorder {
  border-color: red !important;
}

.error_data_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px dashed #ddd;
  padding: 5px;
  margin-bottom: 10px;
}

.disableTopButton {
  background: unset;
}

button:disabled {
  cursor: not-allowed;
}


:root {
  --default-font: "Roboto",  system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Raleway",  sans-serif;
  --nav-font: "Poppins",  sans-serif;
}

:root { 
  --background-color: #ffffff;
  --default-color: #444444;
  --heading-color: #008cd1;
  --accent-color: #2487ce;
  --surface-color: #ffffff;
  --contrast-color: #ffffff;
}

:root {
  --nav-color: #008cd1;
  --nav-hover-color: #2487ce;
  --nav-mobile-background-color: #ffffff;
  --nav-dropdown-background-color: #ffffff;
  --nav-dropdown-color: #008cd1;
  --nav-dropdown-hover-color: #2487ce;
}

.light-background {
  --background-color: #f6fafd;
  --surface-color: #ffffff;
}

.dark-background {
  --background-color: #060606;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --surface-color: #252525;
  --contrast-color: #ffffff;
}

.accent-background {
  --background-color: #2487ce;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --accent-color: #ffffff;
  --surface-color: #469fdf;
  --contrast-color: #ffffff;
}
:root {
  scroll-behavior: smooth;
}

body {
  color: var(--default-color);
  background-color: var(--background-color);
  font-family: var(--default-font);
}

a {
  color: var(--accent-color);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: color-mix(in srgb, var(--accent-color), transparent 25%);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}

/* Pulsating Play Button
------------------------------*/
.pulsating-play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(var(--accent-color) 50%, color-mix(in srgb, var(--accent-color), transparent 75%) 52%);
  border-radius: 50%;
  display: block;
  position: relative;
  overflow: hidden;
}

.pulsating-play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-play-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid color-mix(in srgb, var(--accent-color), transparent 30%);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.pulsating-play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.pulsating-play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.pulsating-play-btn:hover:after {
  border-left: 15px solid var(--accent-color);
  transform: scale(20);
}

@keyframes pulsate-play-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/* PHP Email Form Messages
------------------------------*/
.php-email-form .error-message {
  display: none;
  background: #df1529;
  color: #ffffff;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .sent-message {
  display: none;
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .loading {
  display: none;
  background: var(--surface-color);
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--accent-color);
  border-top-color: var(--surface-color);
  animation: php-email-form-loading 1s linear infinite;
}

@keyframes php-email-form-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.footer {
  background-color: var(--accent-color);
  color: var(--contrast-color);
  border-top: 1px solid color-mix(in srgb, var(--accent-color), transparent 80%);
  font-size: 14px;
  padding-bottom: 0px;
  position: relative;
}

.footer .footer-top {
  padding-top: 50px;
}

.footer .footer-about .logo {
  line-height: 1;
  margin-bottom: 25px;
}

.footer .footer-about .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-about .logo span {
  color: var(--heading-color);
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: var(--heading-font);
}

.footer .footer-about p {
  font-size: 14px;
  font-family: var(--heading-font);
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid color-mix(in srgb, var(--accent-color), transparent 50%);
  font-size: 16px;
  color: var(--accent-color);
  margin-right: 10px;
  transition: 0.3s;
  background-color: #fff;
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul i {
  padding-right: 2px;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: #fff;
  display: inline-block;
  line-height: 1;
}

.footer .copyright {
  padding-top: 15px;
  padding-bottom: 15px;
}

.footer .copyright p {
  margin-bottom: 0;
  font-size: 12px;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 999999;
  overflow: hidden;
  background: var(--background-color);
  transition: all 0.6s ease-out;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #ffffff;
  border-color: var(--accent-color) transparent var(--accent-color) transparent;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1.5s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Scroll Top Button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background-color: var(--accent-color);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: var(--contrast-color);
  line-height: 0;
}

.scroll-top:hover {
  background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
  color: var(--contrast-color);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Global Page Titles & Breadcrumbs
--------------------------------------------------------------*/
.page-title {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 120px 0;
  text-align: center;
  position: relative;
}

.page-title h1 {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 10px;
}

.page-title .breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.page-title .breadcrumbs ol a {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.page-title .breadcrumbs ol li+li {
  padding-left: 10px;
}

.page-title .breadcrumbs ol li+li::before {
  content: "/";
  display: inline-block;
  padding-right: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

/*--------------------------------------------------------------
# Global Sections
--------------------------------------------------------------*/
section,
.section {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 60px 0;
  scroll-margin-top: 100px;
  overflow: clip;
}

@media (max-width: 1199px) {

  section,
  .section {
    scroll-margin-top: 76px;
  }
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.section-title {
  text-align: center;
  padding-bottom: 60px;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: calc(100vh - 100px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0 60px 0;
  overflow: hidden;
}

.hero:before {
  content: "";
  background: color-mix(in srgb, var(--background-color), transparent 30%);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.hero img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.hero .container {
  position: relative;
  z-index: 3;
}

.hero h1 {
  margin: 0;
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
}

.hero p {
  margin: 10px 0 0 0;
  font-size: 22px;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

@media (max-width: 992px) {
  .hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .hero p {
    font-size: 18px;
    line-height: 24px;
  }
}

.hero .icon-box {
  background-color: var(--surface-color);
  padding: 10px 20px;
  position: relative;
  box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hero .icon-box span {
  position: absolute;
  font-size: 11px;
  background: #f1b607;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100px;
  border-radius: 5px;
  margin: auto;
  top: -10px;
  color: #fff;
  text-align: center;
}

.hero .icon-box .title {
  font-weight: 700;
  margin-bottom: 0px;
  font-size: 14px;
  width: 100%;
  display: block;
  text-align: center
}

.hero .icon-box .title a {
  color: var(--heading-color);
  transition: 0.3s;
}

.hero .icon-box .description {
  font-size: 15px;
  margin-bottom: 0;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.hero .icon-box .icon {
  margin-bottom: 20px;
  padding-top: 10px;
  width: 100%;
  display: block;
  transition: all 0.3s ease-in-out;
  line-height: 1;
  color: var(--accent-color);
  text-align: center;
}

.hero .icon-box .icon img {
  width: 30px;
  position: relative;
  margin: auto;
}

@media (min-width: 640px) {
  .hero .icon-box:hover {
    transform: scale(1.08);
  }

  .hero .icon-box:hover .title a {
    color: var(--accent-color);
  }
}

/*--------------------------------------------------------------
# About Alt Section
--------------------------------------------------------------*/
.about-alt .content h3 {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--accent-color);
}

.about-alt .content ul {
  list-style: none;
  padding: 0;
}

.about-alt .content ul li {
  padding: 10px 0 0 0;
  display: flex;
}

.about-alt .content ul i {
  color: var(--accent-color);
  margin-right: 0.5rem;
  line-height: 1.2;
  font-size: 1.25rem;
}

.about-alt .content p:last-child {
  margin-bottom: 0;
}

.about-alt .pulsating-play-btn {
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
}

/*--------------------------------------------------------------
# Clients Section
--------------------------------------------------------------*/
.clients {
  padding: 20px 0;
}

.clients .client-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.clients .client-logo img {
  padding: 5px;
  max-width: 100%;
  transition: 0.3s;
  opacity: 0.5;
  filter: grayscale(100);
}

.clients .client-logo img:hover {
  filter: none;
  opacity: 1;
  transform: scale(1.1);
}

@media (max-width: 640px) {
  .clients .client-logo img {
    padding: 20px;
  }
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .service-item {
  background-color: var(--surface-color);
  box-shadow: 0px 5px 90px 0px rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 60px 30px;
  text-align: center;
  transition: 0.3s;
  border-radius: 5px;
}

.services .service-item .icon {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  position: relative;
}

.services .service-item .icon img {
  width: 36px;
  transition: 0.5s;
  position: relative;
}

.services .service-item .icon svg {
  position: absolute;
  top: 0;
  left: 0;
}

.services .service-item .icon svg path {
  transition: 0.5s;
  fill: color-mix(in srgb, var(--default-color), transparent 95%);
}

.services .service-item h3 {
  font-weight: 700;
  margin: 10px 0 15px 0;
  font-size: 22px;
}

.services .service-item p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .service-item:hover {
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.1);
}

.services .service-item.item-cyan:hover .icon path {
  fill: #0dcaf0;
    opacity: 0.2;
}

.services .service-item.item-orange:hover .icon path {
  fill: #fd7e14;
    opacity: 0.2;
}

.services .service-item.item-teal:hover .icon path {
  fill: #20c997;
    opacity: 0.2;
}

.services .service-item.item-red:hover .icon path {
  fill: #df1529;
    opacity: 0.2;
}
.services .service-item.item-indigo:hover .icon path {
  fill: #6610f2;
    opacity: 0.2;
}

.services .service-item.item-pink:hover .icon path {
  fill: #f3268c;
    opacity: 0.2;
}