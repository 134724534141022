@import "./assets/styles/main.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.p-datepicker table td {
  padding: 3px;
}

.p-datepicker table td > span {
  width: 30px;
  height: 30px;
  font-size: 14px;
}

.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 13px;
}

.p-datepicker table td > span.p-highlight,
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #fff;
  background: #20c997;
  font-weight: 500;
}

.p-datepicker table {
  font-size: 1rem;
  margin: 0;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: #495057;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  font-weight: 600;
  padding: 0 10px;
}

.p-datepicker .p-datepicker-header {
  padding: 3px 0;
}

.p-calendar {
  width: 100%;
}

span.error-msg {
	font-size: 11px;
	color: #dc3545;
	font-weight: 400;
	text-transform: math-auto;
	position: absolute;
	left: 0;
	bottom: -20px;
	line-height: 11px;
  }

  .errorBorder,
  .main-error-border {
	border-color: #dc3545 !important;
  }

  .input_group .css-1u9des2-indicatorSeparator {
	display: none;
  }

button .spinner-border {
	width: 15px;
	height: 15px;
	margin-left: 10px;
  }


.btn_animate {
	animation: bounce 1s;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	-webkit-animation-name: bounce;
	-webkit-animation-duration: 1s;
	-webkit-animation-direction: alternate;
	-webkit-animation-iteration-count: infinite;
  }

  @keyframes bounce {
	from {
		transform: translate3d(0, 10px, 0);
	}
	to {
		transform: translate3d(0, 0, 0);
	}
  }

  @-webkit-keyframes bounce {
	from {
		-webkit-transform: translate3d(0, 10px, 0);
		transform: translate3d(0, 10px, 0);
	}
	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
  }


#login_popup_main_reset.main_custom_popup .main_custom_popup_inner {
	margin-top: unset !important;
	margin: auto !important;
	background-color: #fff;
  }

  #login_popup_main_reset.main_custom_popup .main_custom_popup_inner .login_popup_data {
	text-align: center;
	padding-bottom: 0px;
  }

  #login_popup_main_reset.main_custom_popup .main_custom_popup_inner .login_popup_data p {
	font-size: 13px;
	color: #111;
	font-weight: 500;
	margin-bottom: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  }

  #login_popup_main_reset.main_custom_popup .main_custom_popup_inner .login_popup_data p a.quote_url {
	background-color: #20c997;
	font-weight: 600;
	cursor: pointer;
	color: #fff;
	padding: 5px 15px;
	border-radius: 30px;
	display: table-caption;
	width: auto;
	margin-top: 15px;
	margin-bottom: 15px;
  }


/* ************************custom_loader********************************** */

.cs_custom_lodar {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: #00000129;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.cs_custom_lodar .inner_div {
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  padding: 6px;
  margin: auto;
  top: 43%;
  position: absolute;
  right: 0;
  left: 0;
}
.smiley {
	width: 8em;
	height: 8em;
}
.smiley__eye1,
.smiley__eye2,
.smiley__mouth1,
.smiley__mouth2 {
	animation: eye1 3s ease-in-out infinite;
}
.smiley__eye1,
.smiley__eye2 {
	transform-origin: 64px 64px;
}
.smiley__eye2 {
	animation-name: eye2;
}
.smiley__mouth1 {
	animation-name: mouth1;
}
.smiley__mouth2 {
	animation-name: mouth2;
	visibility: hidden;
}

/* Animations */
@keyframes eye1 {
	from {
		transform: rotate(-260deg) translate(0,-56px);
	}
	50%,
	60% {
		animation-timing-function: cubic-bezier(0.17,0,0.58,1);
		transform: rotate(-40deg) translate(0,-56px) scale(1);
	}
	to {
		transform: rotate(225deg) translate(0,-56px) scale(0.35);
	}
}
@keyframes eye2 {
	from {
		transform: rotate(-260deg) translate(0,-56px);
	}
	50% {
		transform: rotate(40deg) translate(0,-56px) rotate(-40deg) scale(1);
	}
	52.5% {
		transform: rotate(40deg) translate(0,-56px) rotate(-40deg) scale(1,0);
	}
	55%,
	70% {
		animation-timing-function: cubic-bezier(0,0,0.28,1);
		transform: rotate(40deg) translate(0,-56px) rotate(-40deg) scale(1);
	}
	to {
		transform: rotate(150deg) translate(0,-56px) scale(0.4);
	}
}
@keyframes eyeBlink {
	from,
	25%,
	75%,
	to {
		transform: scaleY(1);
	}
	50% {
		transform: scaleY(0);
	}
}
@keyframes mouth1 {
	from {
		animation-timing-function: ease-in;
		stroke-dasharray: 0 351.86;
		stroke-dashoffset: 0;
	}
	25% {
		animation-timing-function: ease-out;
		stroke-dasharray: 175.93 351.86;
		stroke-dashoffset: 0;
	}
	50% {
		animation-timing-function: steps(1,start);
		stroke-dasharray: 175.93 351.86;
		stroke-dashoffset: -175.93;
		visibility: visible;
	}
	75%,
	to {
		visibility: hidden;
	}
}
@keyframes mouth2 {
	from {
		animation-timing-function: steps(1,end);
		visibility: hidden;
	}
	50% {
		animation-timing-function: ease-in-out;
		visibility: visible;
		stroke-dashoffset: 0;
	}
	to {
		stroke-dashoffset: -351.86;
	}
}

.reference_pagecss {
    position: relative;
    padding-top: 50px;
    padding: 30px 0px;
    position: relative;
    background-color: #00565028;
    height: 100vh;
    overflow-y: auto;
}
.reference_pagecss .images_box_ref {
    padding: 25px;
}

.reference_pagecss_inner {
    padding: 20px;
    background: #00565022;
    border-radius: 10px;
    border: 1px solid #00565056;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.reference_pagecss_inner p {
    margin: 0px;
    font-size: 14px;
    color: #005650;
    text-align: center;
	line-height: 24px;
}

.foundproduct {
    width: 100%;
}

.foundproductlist {
    height: 100%;
    display: flex;
    align-items: center;
}

.foundproductlist ul {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #e4eef9;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.foundproductlist ul li {
    width: calc(100% / 2 - 20px);
    margin: 10px;
}

.foundproductlist ul li a {
    display: flex;
    align-items: center;
    border: 2px solid #00565056;
    padding: 15px;
    border-radius: 5px;
    background: #fff;
    position: relative;
}

.foundproductlist ul li a img {
    width: 60px;
}

.foundproductlist ul li a b {
    font-weight: 500;
    display: block;
    font-size: 16px;
    color: #0d0d0d;
    line-height: 24px;
    letter-spacing: 0px;
}

.foundproductlist ul li a small {
    font-size: 14px;
    margin-top: 5px;
    line-height: normal;
    color: #111;
    margin-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .reference_pagecss_inner{
        padding: 25px;
        width: 100%;
    }
    .reference_pagecss {
        padding: 35px 0px;
        margin-bottom: 30px;
    }
    .foundproduct {
        margin: 35px 0px;
    }
    .foundproductlist ul li {
        width: calc(100% / 2 - 10px);
        margin: 5px;
    }
}
@media only screen and (min-width: 577px) and (max-width: 767px) {
    .reference_pagecss_inner{
        padding: 20px;
        width: 100%;
        margin: 0 auto;
    }
    .reference_pagecss {
        margin-bottom: 0;
        padding: 25px 0px;
    }
    .foundproductlist ul li {
        width: calc(100% / 3 - 10px);
        margin: 5px;
    }
    .foundproductlist ul li a {
        display: block;
        text-align: center;
    }
    .foundproductlist ul li a small {
        margin-left: 0;
        margin-top: 10px;
        display: block;
    }
    .foundproduct {
        margin: 30px 0px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 576px) {
    .reference_pagecss_inner{
        padding: 15px;
        width: 100%;
    }
    .reference_pagecss {
        margin-bottom: 0;
        padding: 20px 0px;
    }
    .foundproduct {
        margin: 20px 0px;
    }
    .foundproductlist ul li {
        width: calc(100% / 2 - 6px);
        margin: 5px 3px;
    }
    .foundproductlist ul li a {
        display: block;
        text-align: center;
    }
    .foundproductlist ul li a small {
        margin-top: 5px;
        margin-left: 0;
        display: block;
    }
}

/* ************************custom_loader********************************** */

